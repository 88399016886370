<template>
	<div class="flex flex-row flex-wrap justify-start items-start w-screen sm:w-auto h-full gap-5 px-4 py-6 sm:px-6 sm:py-12">
		<a
			v-if="activeTab > 0"
			href=""
			@click.prevent="
				activeTab = 0;
				$router.push('/osmember/archive');
			"
			class="flex sm:hidden justify-center items-center border-2 border-blue-500 text-blue-500 font-semibold p-2 pl-3 rounded-xl"
		>
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1.70711 5.29289C1.31658 5.68342 1.31658 6.31658 1.70711 6.70711L6 11" stroke="#3377FF" stroke-width="2" stroke-linecap="round" />
			</svg>
			<p class="pl-3">Назад</p>
		</a>

		<!-- Заголовок -->
		<h1 class="flex w-full text-xl font-extrabold sm:text-2xl">
			<span class="flex">Архив</span>
		</h1>
		<h2 v-if="activeTab !== 0" class="sm:hidden flex w-full text-lg h-6 mb-4 sm:mb-7 font-extrabold sm:text-2xl">
			<span v-if="activeTab === 1" class="flex">Активность членов ОС по НОК</span>
			<span v-else-if="activeTab === 2" class="flex">Информация по МО</span>
			<span v-else-if="activeTab === 3" class="flex">Контрольные мероприятия</span>
			<span v-else-if="activeTab === 4" class="flex">Назначение МО</span>
		</h2>

		<div class="flex w-full">
			<div class="add-form__select" style="width: 123px">
				<multiselect tag-placeholder="" placeholder="" :show-labels="false" v-model="selectedYear" :options="listYears" :searchable="false" :multiple="false" />
			</div>
		</div>

		<!-- Регион -->
		<div class="flex flex-col justify-between w-full px-5 py-8 sm:w-7/12 sm:h-40 sm:mr-4 white-round-box">
			<p class="flex h-5 my-2 text-md text-gray-400 font-bold">Регион</p>
			<p class="flex h-5 my-2 text-lg text-gray-900 font-bold">
				{{ getAuthUser.regionName }}
			</p>
		</div>

		<!-- Мониторинг, инструкция -->
		<div class="hidden sm:flex flex-col flex-grow justify-between white-round-box w-4/12 sm:h-40 order-3 sm:order-2">
			<a class="flex text-sm text-blue-500 underline" href="https://static-0.minzdrav.gov.ru/system/attachments/attaches/000/041/812/original/Мониторинг__НОК.pdf?1544167048" target="_blank">
				О мониторинге проведения независимой оценки качества условий оказания услуг медицинскими организациями в субъектах Российской Федерации
			</a>
			<a class="flex text-sm text-gray-500 underline" :href="calcHref" target="_blank">
				<span v-if="chairman">Инструкция пользователя "Председателя ОС"</span>
				<span v-if="secretary">Инструкция пользователя "Секретарь ОС"</span>
			</a>
		</div>

		<div class="flex flex-col w-full order-2">
			<!-- Табы -->
			<ul class="hidden sm:flex w-1/2 z-50 pt-2 flex-col sm:flex-row sm:gap-x-5 sm:gap-y-0 sm:-mb-8 bg-transparent">
				<li
					v-if="!osMember"
					class="flex px-4 py-3.5 font-bold rounded-t-lg cursor-pointer"
					:class="{ 'bg-blue-500 text-white border-none': activeTab === 1, 'border-t border-r border-l border-gray-400 text-gray-400 bg-white ': activeTab != 1 }"
					@click.prevent="activeTab = 1"
					href=""
				>
					Активность членов ОС
				</li>
				<li
					v-if="!osMember"
					class="flex px-4 py-3.5 font-bold rounded-t-lg cursor-pointer"
					:class="{ 'bg-blue-500 text-white border-none': activeTab === 2, 'border-t border-r border-l border-gray-400 text-gray-400 bg-white ': activeTab != 2 }"
					@click.prevent="activeTab = 2"
					href=""
				>
					Информация по МО
				</li>
				<li
					class="flex px-4 py-3.5 font-bold rounded-t-lg cursor-pointer"
					:class="{ 'bg-blue-500 text-white border-none': activeTab === 3, 'border-t border-r border-l border-gray-400 text-gray-400 bg-white ': activeTab != 3 }"
					@click.prevent="activeTab = 3"
					href=""
				>
					Контрольные мероприятия
				</li>
				<li
					v-if="!osMember && !chairman"
					class="flex px-4 py-3.5 font-bold rounded-t-lg cursor-pointer"
					:class="{ 'bg-blue-500 text-white border-none': activeTab === 4, 'border-t border-r border-l border-gray-400 text-gray-400 bg-white ': activeTab != 4 }"
					@click.prevent="activeTab = 4"
					href=""
				>
					Назначение МО
				</li>
			</ul>

			<ul v-if="activeTab === 0" class="flex sm:hidden w-full flex-col rounded-lg pb-2 overflow-hidden bg-white filter shadow-default">
				{{
					osMember
				}}
				<li
					v-if="!osMember"
					class="flex items-center px-10 py-4 text-gray-600 border-b border-gray-300 hover:bg-blue-50 hover:text-blue-500 cursor-pointer"
					:class="{ 'bg-blue-50 text-blue-500 font-bold': activeTab == 1 }"
					@click.prevent="activeTab = 1"
					href=""
				>
					Активность членов ОС
					<img class="ml-auto" src="@/assets/img/arrow-right.png" />
				</li>
				<li
					v-if="!osMember"
					class="flex items-center px-10 py-4 text-gray-600 border-b border-gray-300 hover:bg-blue-50 hover:text-blue-500 cursor-pointer"
					:class="{ 'bg-blue-50 text-blue-500 font-bold': activeTab == 2 }"
					@click.prevent="activeTab = 2"
					href=""
				>
					Информация по МО
					<img class="ml-auto" src="@/assets/img/arrow-right.png" />
				</li>
				<li
					class="flex items-center px-10 py-4 text-gray-600 border-b border-gray-300 hover:bg-blue-50 hover:text-blue-500 cursor-pointer"
					:class="{ 'bg-blue-50 text-blue-500 font-bold': activeTab == 3 }"
					@click.prevent="activeTab = 3"
					href=""
				>
					Контрольные мероприятия
					<img class="ml-auto" src="@/assets/img/arrow-right.png" />
				</li>
				<li
					v-if="!osMember && !chairman"
					class="flex items-center px-10 py-4 text-gray-600 border-b border-gray-300 hover:bg-blue-50 hover:text-blue-500 cursor-pointer"
					:class="{ 'bg-blue-50 text-blue-500 font-bold': activeTab == 4 }"
					@click.prevent="activeTab = 4"
					href=""
				>
					Назначение МО
					<img class="ml-auto" src="@/assets/img/arrow-right.png" />
				</li>
			</ul>

			<Activity v-if="activeTab === 1 && !osMember" class="sm:border-t-2 sm:border-blue-500" :archive="true" :selectedYear="selectedYear" />

			<InfoMO v-if="activeTab === 2 && !osMember" class="sm:border-t-2 sm:border-blue-500" :archive="true" :selectedYear="selectedYear" />

			<ControlEvent v-if="activeTab === 3" class="sm:border-t-2 sm:border-blue-500" :archive="true" :selectedYear="selectedYear" />

			<AssignMO v-if="activeTab === 4 && !osMember && !chairman" class="sm:border-t-2 sm:border-blue-500" :archive="true" :selectedYear="selectedYear" />
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Activity from '../../Activities/index.vue';
import InfoMO from '../../InfoMO/index.vue';
import ControlEvent from '../../ControlEvent/index.vue';
import AssignMO from '../../AssignMO/index.vue';
import { getters } from '@/store/store';

export default {
	name: 'Main',
	components: {
		Multiselect,
		Activity,
		InfoMO,
		ControlEvent,
		AssignMO
	},
	computed: {
		...getters,
		osMember() {
			return this.getAuthUser.roles.indexOf('OS_Member') !== -1;
		},
		chairman() {
			return this.getAuthUser.roles.indexOf('OS_Chairman') !== -1;
		},
		secretary() {
			return this.getAuthUser.roles.indexOf('OS_Secretary') !== -1;
		},
		calcHref() {
			return this.chairman
				? 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя председатель ОС.pdf'
				: 'https://nok.minzdrav.gov.ru/docs/Оценка ОС. Инструкция пользователя секретарь ОС.pdf';
		}
	},
	data() {
		return {
			mobile: false,
			activeTab: 1,
			listYears: [],
			selectedYear: 2022
		};
	},
	created() {
		if (this.osMember) {
			this.activeTab = 3;
		}

		this.mobile = window.document.documentElement.clientWidth < 636;
		if (this.mobile) this.activeTab = 0;

		if (this.$route.query.activeTab) {
			this.activeTab = +this.$route.query.activeTab;
		}

		this.$http.get(`Region/GetRegionYearData?regionId=${this.getAuthUser.regionId}`).then((res) => {
			this.listYears = res.data.closedYears;
			if (this.listYears.length > 0) {
				this.selectedYear = this.listYears[this.listYears.length - 1];
			}
		});
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.white-round-box {
	@apply flex px-10 py-8 rounded-lg bg-white filter drop-shadow-default;
}

.add-form__select > .multiselect {
	margin-bottom: 0;
}

.add-form__select .multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 40px;
	height: 50px !important;
	max-width: 100% !important;
	width: 100%;
	border-radius: 10px;
	border: 2px solid #3377ff;
	padding: 0 20px;
	color: #6a6e83;
	background: initial;
	display: flex;
	align-items: center;
}

.add-form__select .multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
	overflow: hidden;
}

.add-form__select .multiselect__tag {
	padding: 0;
	color: #6a6e83;
	margin-right: 0;
	background: transparent !important;
	overflow: visible;
}

.add-form__select .multiselect__tag-icon {
	display: none;
}

.add-form__select .multiselect__tag:not(:first-child)::before {
	content: ',';
	margin-right: 5px;
}

.add-form__select .multiselect__option--highlight:after {
	display: none;
}

.add-form__select .multiselect__select {
	height: 50px;
}

.add-form__select .multiselect__placeholder {
	margin-bottom: 3px !important;
}

.add-form__select .multiselect__single {
	line-height: 18px !important;
	font-size: 18px;
	color: #3377ff;
	padding: 5px;
}
</style>
